<template>
  <div id="nav" ref="navRef">
    <!-- <router-link to="/">Home</router-link> | -->
    <!-- {{ router.currentRoute.fullPath }}
    v-if="router.currentRoute.fullPath != '/article'" -->
    <div class="nav_left">
      <div
        class="iconfont"
        @click="backToIndex"
        :style="{ color: colorFilter(router.currentRoute.value.fullPath)[0] }"
      >
        &#xe601;
      </div>
      <!-- <span>{{ router.currentRoute.value.fullPath }}</span> -->
    </div>
    <div class="nav_right">
      <div
        v-for="(item, index) in navArr"
        :key="index"
        class="nav_rightItem"
        :ref="setRef1"
      >
        <span class="iconfont" v-if="index != navArr.length">&#xe722;</span>
        <router-link
          :style="{ color: colorFilter(router.currentRoute.value.fullPath)[0] }"
          :to="item.to"
          @mouseover.native="handleMouseover(item, index)"
          @mouseout.native="handleMouseout(item, index)"
          @click="handleClick(item, index)"
        >
          {{ $i18n.locale == "ch" ? item.title : item.titleEn }}</router-link
        >
      </div>
    </div>
    <div class="languageBtn" @click="switchLocale">
      {{ $i18n.locale == "ch" ? "EN" : "中文" }}
    </div>
  </div>
  <!-- style="
      position: fixed;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      right: 50px;
      bottom: 50px;
      z-index: 999999;" -->
  <div @click="showMessgeHandle" class="imgBox">
    <img
      src="@/assets/common/kefu.png"
      style="width: inherit; border-radius: inherit; cursor: pointer"
    />
  </div>
  <router-view />
  <Footer
    :backgroundColor="colorFilter(router.currentRoute.value.fullPath)[2]"
    :pagePosition="colorFilter(router.currentRoute.value.fullPath)[3]"
    @handle-click="handleClick"
  ></Footer>
  <chatBox></chatBox>
</template>

<script>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import i18n from "@/language/index";
import Footer from "./components/Footer.vue";
import chatBox from "./components/chatBox";
export default {
  components: { Footer, chatBox },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const proxy = getCurrentInstance();
    // arrow color:active颜色
    const navRef = ref(null);
    const navArr = ref([
      {
        to: "/",
        title: "首页",
        titleEn: "Home",
        color: "#8c7bd9",
        show: false,
      },
      {
        to: "/about",
        title: "关于我们",
        titleEn: "About us",
        color: "#e92ea3",
        show: false,
      },
      {
        to: "",
        title: "数据库",
        titleEn: "TD DATA",
        color: "#4f24ee",
        show: false,
      },
      {
        to: "/technology",
        title: "技术服务",
        titleEn: "Technical service",
        color: "#3399ff",
        show: false,
      },
      {
        to: "/product",
        title: "产品",
        titleEn: "Product",
        color: "#add800",
        show: false,
      },
      {
        to: "/article",
        title: "热门文章",
        titleEn: "Hot article",
        color: "#16c494",
        show: false,
      },
      {
        to: "/contact",
        title: "联系我们",
        titleEn: "Contact",
        color: "#e92ea3",
        show: false,
      },
      // {
      //   to: "",
      //   title: "English",
      //   titleEn: "中文",
      //   color: "#ffb607",
      //   show: false,
      // },
    ]);
    onMounted(() => {
      // console.log(" app onMounted", router.currentRoute.value);
      // document.getElementById('myDialog').showModal();
    });

    const refArr = ref([]);
    const setRef1 = function (e) {
      refArr.value.push(e);
    };
    const backToIndex = function () {
      router.push("/");
    };
    // 移入
    const handleMouseover = function (item, index) {
      // console.log("handleMouseover",href_href);
      if (index < navArr.value.length) {
        // arrow
        refArr.value[index].children[0].style.opacity = 1;
        refArr.value[index].children[0].style.color = item.color;
        refArr.value[index].children[1].style.color = item.color;
        refArr.value[index].style.transform = "translate(10px,0px)";
        refArr.value[index].style.transition = "all 0.4s";
      }
    };
    // 移出
    const handleMouseout = function (item, index) {
      let href_href = router.currentRoute.value.fullPath;
      // console.log(
      //   "handleMouseout",
      //   item.to,
      //   href_href,
      //   navRef.value.style.backgroundColor
      // );

      if (index < navArr.value.length) {
        // arrow
        refArr.value[index].children[0].style.opacity = 0;
        refArr.value[index].children[0].style.color = "";
        refArr.value[index].children[1].style.color =
          item.to == href_href
            ? colorFilter(item.to)[1]
            : colorFilter(href_href)[0];
        if (navRef.value.style.backgroundColor == "rgb(234, 232, 228)") {
          refArr.value[index].children[1].style.color = "#2D2D2D";
        }
        refArr.value[index].style.transform = "translate(0px,0px)";
        refArr.value[index].style.transition = "all 0.4s";
      }
    };

    // 点击
    const handleClick = function (item, index) {
      let href_href = router.currentRoute.value.fullPath;
      // console.log(
      //   "handleClick",
      //   refArr.value[index].children[1],
      //   item.to,
      //   colorFilter(item.to)[1]
      // );
      // arrow

      if (index == navArr.length - 1) {
        refArr.value[index].children[0].style.opacity = 1;
      } else {
        refArr.value[index].children[0].style.opacity = 0;
        refArr.value[index].style.transform = "translate(0px,0px)";
        refArr.value[index].style.transition = "all 0.5s";
        setTimeout(() => {
          refArr.value[index].children[1].style.color = colorFilter(item.to)[1];
        }, 10);
      }

      if (item.to) {
        router.push(item.to);
      }
      if (item.title == "数据库") {
        window.open("https://www.qctddata.com/", "_blank");
      }
      if (index == navArr.length - 1) {
        let current = i18n.global.locale;
        // navArr.value[navArr.value.length - 1].title =
        //   current == "ch" ? "English" : "中文";
        i18n.global.locale = current == "ch" ? "en" : "ch";
      }
    };
    // item通用
    const colorFilter = function (url) {
      // let href_href = router.currentRoute.value.fullPath;
      // 通用-被选中路由-footer背景色
      switch (url) {
        case "/":
          return ["#eae8e4", "blue", "#5729ff", ""];
          break;
        case "/about":
          return ["#2D2D2D", "#ADD800", "#448d79", "absolute"];
          break;
        case "/article":
          return ["#2d2d2d", "blue", "#99bf00", ""];
          break;
        // case "/searchArticle":
        //   return ["#2d2d2d", "blue", "#99bf00"];
        //   break;
        case "/technology":
          return ["#CBF6FF", "#3399FF", "#0061c2", ""];
          break;
        case "/product":
          return ["#EDFFAB", "#ADD800", "#950341", ""];
          break;
        case "/contact":
          return ["#2d2d2d", "blue", "#db6800", ""];
          break;
        default:
          // return ["#eae8e4", "blue", "#5729ff"];
          return ["#2d2d2d", "blue", "#99bf00", ""];
          break;
      }
    };
    // document.onscroll = function () {
    //   console.log("app scroll");
    // };
    const switchLocale = function () {
      // console.log(i18n);
      i18n.global.locale = i18n.global.locale == "ch" ? "en" : "ch";
    };

    const showMessgeHandle = function () {
      document.getElementById("myDialog").showModal();
    };

    return {
      backToIndex,
      colorFilter,
      router,
      navArr,
      setRef1,
      handleMouseover,
      handleMouseout,
      handleClick,
      navRef,
      switchLocale,
      showMessgeHandle,
    };
  },
};
</script>
<style lang="scss">
@import "../src/assets/style/common.scss";
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
  margin: 0px;
  padding: 0px;
  background-color: #eae8e4;
}
.imgBox {
  position: fixed;
  width: 80px;
  // height: 60px;
  border-radius: 30px;
  right: 50px;
  bottom: 50px;
  z-index: 999999;
}
#nav {
  position: fixed;
  // top: 2vh;
  left: 0;
  width: 100%;
  height: 4vh;
  @include rowBetweenCenter;
  // border-bottom: 1px solid #2d2d2d2d;
  z-index: 1000;
  padding-bottom: 1.5vh;
  padding-top: 1.5vh;
  .nav_left {
    position: absolute;
    z-index: 2000;
    .iconfont {
      // font-size: 44px;
      font-size: 2.3vw;
      color: #2d2d2d;
      margin-left: 3.05vw;
      margin-left: 2.5vw;
      cursor: pointer;
    }
  }

  .nav_right {
    position: absolute;
    display: flex;
    color: #eae8e4;
    right: 3.05vw;
    z-index: 2000;
    .nav_rightItem {
      padding: 0vw 0.9vw;
      cursor: pointer;
      a {
        font-size: 1.2vw;
        color: #2d2d2d;
        text-decoration: none;
      }
      .iconfont {
        opacity: 0;
        font-size: 0.96vw;
        transition: all 0.6s;
      }
    }
  }
  .languageBtn {
    position: absolute;
    color: #8c7bd9;
    font-size: 1.2vw;
    font-weight: bold;
    right: 1vw;
    cursor: pointer;
  }
  .chat_Btn {
    position: fixed;
    right: 80px;
    bottom: 100px;
    width: 80px;
    height: auto;
    z-index: 999999;
    background-color: #fff;
    border-radius: 40px;
  }
}
</style>
