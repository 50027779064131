module.exports = {
    public: {
        companyName: "Thousand Dimensions ( Beijing ) Science and Technology Co., Ltd",
        shareWork: "Share our work and research articles",
        // order: "Order",
        // aboutus: "aboutus",
        // tddata: "TD DATA",
        // technicalService: "Technical service",
        // product: "Products",
        // article: "Hot articles",
        // contact: "Contact us",
    }
}