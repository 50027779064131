<template>
  <div
    class="main3"
    :style="{ 'background-color': backgroundColor, position: pagePosition }"
  >
    <div class="main3Inner" ref="main3InnerRef">
      <h1 class="main31">
        {{
          $i18n.locale == "ch"
            ? "千乘镜像（北京）科技有限公司"
            : "Thousand Dimensions ( Beijing ) Science and Technology Co., Ltd"
        }}
      </h1>
      <div class="main32">
        <div class="nav_right">
          <div
            v-for="(item, index) in navArr"
            :key="index"
            class="nav_rightItem"
            :ref="setRef1"
          >
            <span class="iconfont" v-if="index != navArr.length">&#xe722;</span>
            <!--         @click="handleClick(item)"  @click="$emit('handleClick', item)"-->
            <span
              :class="{ navTitle: true }"
              @mouseover.native="handleMouseover(item, index)"
              @mouseout.native="handleMouseout(item, index)"
              @click="$emit('handleClick', item, index)"
            >
              {{ $i18n.locale == "ch" ? item.title : item.titleEn }}</span
            >
          </div>
        </div>

        <div
          class="barCode"
          ref="barCodeRef"
          @mouseenter="barCode1MouseEnter"
          @mouseleave="barCode1MouseLeave"
        >
          <!-- <div class="forDownLoad">
            <img src="@/assets/fileDownLoad.png" alt="" class="fileIcon" />
          </div> -->
          <img
            src="@/assets/fileDownLoad.png"
            alt=""
            class="fileIcon"
            @click="downLoadTSmanual"
          />
          <!-- <span class="gap"></span> -->
          <img
            src="@/assets/gzhao1.png"
            alt=""
            class="barCode1"
            @mousemove="barCodeMouseMove(1, $event)"
          />
          <span class="gap" @mousemove="barCodeMouseMove(3, $event)"></span>
          <img
            src="@/assets/gzhao2.png"
            alt=""
            class="barCode2"
            @mousemove="barCodeMouseMove(2, $event)"
          />
          <img src="" alt="" class="barCode11" ref="barCode11Ref" />
          <!-- <img src="@/assets/gzhao22.png" alt="" class="barCode22" /> -->
        </div>
      </div>
      <div class="main33">
        <div class="main331">
          Copyright ©
          {{
            $i18n.locale == "ch"
              ? "千乘镜像(北京)科技有限公司"
              : "Thousand Dimensions ( Beijing ) Science and Technology Co., Ltd"
          }}
        </div>
        <!-- <div class="main332">京ICP备2020125445号</div> -->
        <div class="main332">京ICP备2020032311号-1</div>
      </div>
    </div>
    <!-- @click="$emit('handleClick', item, index)" -->
  </div>
</template>
<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import gzhao11 from "@/assets/gzhao11.png";
import gzhao22 from "@/assets/gzhao22.png";
export default {
  props: ["backgroundColor", "pagePosition"],
  setup() {
    const router = useRouter();
    const main3InnerRef = ref(null);
    const copySrc = ref("");
    const barCode11Ref = ref(null);
    const navArr = ref([
      {
        to: "/",
        title: "首页",
        titleEn: "Home",
        color: "#dcff93",
        show: false,
      },
      {
        to: "/about",
        title: "关于我们",
        titleEn: "About us",
        color: "#e92ea3",
        show: false,
      },
      {
        to: "",
        title: "数据库",
        titleEn: "TD DATA",
        color: "#f7ac18",
        show: false,
      },
      {
        to: "/technology",
        titleEn: "Technical service",
        title: "技术服务",
        color: "#3399ff",
        show: false,
      },
      {
        to: "/product",
        titleEn: "Products",
        title: "产品",
        color: "#add800",
        show: false,
      },
      {
        to: "/article",
        titleEn: "Hot articles",
        title: "热门文章",
        color: "#16c494",
        show: false,
      },
      {
        to: "/contact",
        titleEn: "Contact us",
        title: "联系我们",
        color: "#f97600",
        show: false,
      },
    ]);
    const refArr = ref([]);
    const setRef1 = function (e) {
      refArr.value.push(e);
    };
    const barCodeRef = ref(null);
    const handleMouseover = function (item, index) {
      // navArr.value[index].show = !navArr.value[index].show;
      // console.log("handleMouseover");
      if (index < navArr.value.length) {
        refArr.value[index].children[0].style.opacity = 1;
        refArr.value[index].children[0].style.color = item.color;
        refArr.value[index].children[1].style.color = item.color;
        refArr.value[index].style.transform = "translate(10px,0px)";
        refArr.value[index].style.transition = "all 0.4s";
      }
    };
    const handleMouseout = function (item, index) {
      // console.log("handleMouseout");
      if (index < navArr.value.length) {
        refArr.value[index].children[0].style.opacity = 0;
        refArr.value[index].children[0].style.color = "";
        refArr.value[index].children[1].style.color = "#fff";
        refArr.value[index].style.transform = "translate(0px,0px)";
        refArr.value[index].style.transition = "all 0.4s";
      }
    };
    const handleClick = function (item) {
      if (item.to) {
        router.push(item.to);
      } else {
        window.open("https://www.qctddata.com/", "_blank");
      }
    };
    const barCode1MouseEnter = function (e) {
      // barCodeRef.value.children[2].style.opacity = 1;
      barCode11Ref.value.src = copySrc.value ? copySrc.value : gzhao11;
    };
    const barCode1MouseLeave = function (e) {
      // barCodeRef.value.children[2].style.opacity = 0;
      console.log("leave");
      barCode11Ref.value.src = "";
      barCode11Ref.value.style.opacity = 0;
    };
    const barCodeMouseMove = function (index, e) {
      console.log("move");
      barCode11Ref.value.style.opacity = 1;
      let fatherTop = main3InnerRef.value.getBoundingClientRect().top;

      copySrc.value =
        index == 1 ? gzhao11 : index == 2 ? gzhao22 : barCode11Ref.value.src;
      barCode11Ref.value.src = copySrc.value;

      barCode11Ref.value.style.left =
        (e.x / document.documentElement.clientWidth) * 100 + "%";

      barCode11Ref.value.style.top =
        ((e.y - fatherTop) / document.documentElement.clientHeight) * 100 -
        barCode11Ref.value.clientHeight / 7 +
        "%";
    };
    const downLoadTSmanual = function () {
      let a = document.createElement("a");
      a.setAttribute(
        "href",
        "https://qcjx.oss-cn-beijing.aliyuncs.com/other/TDTechnicalServiceManual.pdf"
      );
      a.setAttribute("download", "千乘镜像技术服务手册");
      a.setAttribute("target", "_blank");
      // let clickEvent = document.createEvent("MouseEvents");
      // clickEvent.initEvent("click", true, true);
      let clickEvent = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvent);
    };
    return {
      navArr,
      refArr,
      setRef1,
      main3InnerRef,
      handleMouseover,
      handleMouseout,
      handleClick,
      barCodeRef,
      barCode1MouseEnter,
      barCode1MouseLeave,
      barCodeMouseMove,
      barCode11Ref,
      downLoadTSmanual,
    };
  },
};
</script>
<style scoped lang="scss">
@import "../assets/style/common.scss";

.main3 {
  // position: absolute;
  width: 100%;
  margin-top: 0vh;
  .main3Inner {
    position: relative;
    width: 92vw;
    margin: 0 auto;
    .main31 {
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-size: 1.17vw;
      color: #ffffff;
      text-align: left;
      padding-top: 3vh;
      padding-bottom: 3vh;
      padding-left: 3.7vw;
      margin: 0;
    }
    .main32 {
      // position: relative;
      @include rowBetweenCenter;
      padding-top: 1.5vh;
      padding-bottom: 1.5vh;
      padding-left: 2.7vw;
      // padding-right: 6vw;
      padding-right: 2.7vw;
      border-top: 0.05vw solid #ffffff;
      border-bottom: 0.05vw solid #ffffff;
      .nav_right {
        display: flex;

        .nav_rightItem {
          padding-right: 0.9vw;
          cursor: pointer;
          .navTitle {
            font-size: 0.88vw;
            font-weight: 400;
            color: #fff;
          }
          .iconfont {
            opacity: 0;
            font-size: 1vw;
            transition: all 0.6s;
          }
        }
      }
      .barCode {
        // position: relative;
        .fileIcon {
          position: absolute;
          width: 2.5vw;
          height: 2.5vw;
          right: 10.7vw;
          // left: -2.5vw;
          // top: 0;
          transition: all 0.6s;
          cursor: pointer;
        }
        .fileIcon:hover {
          transform: scale(1.2);
        }
        .barCode1 {
          width: 2.5vw;
          height: 2.5vw;
          // margin-right: 1.5vw;
        }
        .gap1 {
          display: inline-block;
          width: 1.5vw;
          height: 2.5vw;
        }
        .gap {
          display: inline-block;
          width: 1.5vw;
          height: 2.5vw;
        }
        .barCode2 {
          width: 2.5vw;
          height: 2.5vw;
        }
        .barCode11 {
          opacity: 0;
          position: absolute;
          width: 4.5vw;
          height: 4.5vw;
        }
        // .barCode22 {
        //   opacity: 0;
        //   position: absolute;
        //   width: 4.5vw;
        //   height: 4.5vw;
        //   top: 0;
        //   right: 5vw;
        //   transition: all 1s;
        // }
      }
    }
    .main33 {
      margin: 0 auto;
      padding-top: 5vh;
      padding-bottom: 5vh;
      div {
        font-size: 0.9vw;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
      }
      .main332 {
        padding-top: 1vh;
      }
    }
  }
}
</style>
