import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "normalize.css"
import "./assets/style/base.scss"
import "./assets/style/iconfont.css"
import VueI18n from "./language";
// import VuePhotoZoomPro from "vue-photo-zoom-pro";
// import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css'
// console.log("VuePhotoZoomPro", VuePhotoZoomPro)
router.afterEach(() => {
    window.scrollTo(0, 0);
})

createApp(App).use(store).use(router).use(VueI18n).mount('#app')
