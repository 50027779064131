import request from '@/utils/request'

// 获取首页articles
export function getArticles(query) {
    return request({
        // url: "/website/article/list",
        url: "/article/list",
        method: 'get',
        params: query
    })
}

export function getSearchArticle(query) {
    return request({
        // url:"/website/article/search",
        url: "/article/search",
        method: 'get',
        params: query
    })
}

export function submitOrder(data) {
    return request({
        // url:"/website/common/technicalService",
        url: "/common/technicalService",
        method: "post",
        data: data
    })
}

