// export 
export const baseUrl = {
    // urlOnline: "http://192.168.8.40:7001",
    // urlOnline: "http://124.70.8.231:8011/website",
    urlOnline: "http://www.qianchengjx.com/website",
    websocket: "ws://124.70.86.188:7001/soloWebSocket/",

}

export const clientInfo = {
    id: "qcjx",
    pass: "qcjx",
}
export function bgColorFn(...arr) {
    let color = arr[arr.length - 1];
    for (let i = 0; i < arr.length - 1; i++) {
        arr[i].style.backgroundColor = color;
    }
}
// ratio, second, third, four, fifth, videoRightDom, videoLeftDom
export function scrollHome(ratio, homeRef, videoRightDom, videoLeftDom) {
    console.log("ratio====", ratio)
    switch (true) {
        case ratio < 0.19:
            if (homeRef) { homeRef.style.backgroundColor = "#eae8e4"; }
            return;
        case ratio >= 0.74:
            if (homeRef) { homeRef.style.backgroundColor = "#dcff93"; }
            return;
        case ratio >= 0.73:
            if (homeRef) { homeRef.style.backgroundColor = "#defb9e"; }
            return;
        case ratio >= 0.72:
            if (homeRef) { homeRef.style.backgroundColor = "#e0f9a8"; }
            return;
        case ratio >= 0.70:
            if (homeRef) { homeRef.style.backgroundColor = "#e1f7b0"; }
            return;
        case ratio >= 0.68:
            if (homeRef) { homeRef.style.backgroundColor = "#e2f4ba"; }
            return;
        case ratio >= 0.66:
            if (homeRef) { homeRef.style.backgroundColor = "#e4f2c2"; }
            return;
        case ratio >= 0.64:
            if (homeRef) { homeRef.style.backgroundColor = "#e6f0ca"; }
            return;
        case ratio >= 0.62:
            if (homeRef) { homeRef.style.backgroundColor = "#e7ecd3"; }
            return;

        case ratio >= 0.60:
            if (homeRef) { homeRef.style.backgroundColor = "#e9eadd"; }
            return;

        case ratio >= 0.46:
            if (homeRef) { homeRef.style.backgroundColor = "#eae8e4"; }
            return;
        case ratio >= 0.45:
            if (homeRef) { homeRef.style.backgroundColor = "#e4e1e3"; }
            return;
        case ratio >= 0.44:
            if (homeRef) { homeRef.style.backgroundColor = "#d9d4e2"; }
            return;
        case ratio >= 0.43:
            if (homeRef) { homeRef.style.backgroundColor = "#cec7e0"; }
            return;
        case ratio >= 0.41:
            if (homeRef) { homeRef.style.backgroundColor = "#c3bbe0"; }
            return;
        case ratio >= 0.39:
            if (homeRef) { homeRef.style.backgroundColor = "#b8aede"; }
            return;
        case ratio >= 0.37:
            if (homeRef) { homeRef.style.backgroundColor = "#ada1dd"; }
            return;
        case ratio >= 0.35:
            if (homeRef) { homeRef.style.backgroundColor = "#a193db"; }
            return;
        case ratio >= 0.34:
            if (homeRef) { homeRef.style.backgroundColor = "#9787da"; }
            return;

        case ratio >= 0.29:
            // ratio >= 0.32:
            videoRightDom.style.width = "46%";
            videoRightDom.style.top = "10%";
            videoRightDom.style.right = "2%";

            videoLeftDom.style.width = "46%";
            videoLeftDom.style.top = "30%";
            videoLeftDom.style.left = "2%";
            if (homeRef) { homeRef.style.backgroundColor = "#8c7bd9"; }
            return;

        case ratio >= 0.28:
            // ratio >= 0.31:
            videoRightDom.style.width = "36%";
            videoRightDom.style.top = "14%";
            videoRightDom.style.right = "12.5%";

            videoLeftDom.style.width = "36%";
            videoLeftDom.style.top = "33%";
            videoLeftDom.style.left = "12.5%";
            if (homeRef) { homeRef.style.backgroundColor = "#9989da"; }
            return;

        case ratio >= 0.26:
            // ratio >= 0.29:
            videoRightDom.style.width = "27%";
            videoRightDom.style.top = "23%";
            videoRightDom.style.right = "21.5%";

            videoLeftDom.style.width = "27%";
            videoLeftDom.style.top = "37%";
            videoLeftDom.style.left = "21.5%";
            if (homeRef) { homeRef.style.backgroundColor = "#a597db"; }
            return;

        case ratio >= 0.24:
            // ratio >= 0.27:
            videoRightDom.style.width = "18%";
            videoRightDom.style.top = "29%";
            videoRightDom.style.right = "30.5%";

            videoLeftDom.style.width = "18%";
            videoLeftDom.style.top = "41%";
            videoLeftDom.style.left = "30.5%";
            if (homeRef) { homeRef.style.backgroundColor = "#b2a6dd"; }
            return;

        case ratio >= 0.22:
            // ratio >= 0.25:
            videoRightDom.style.width = "9%";
            videoRightDom.style.top = "35%";
            videoRightDom.style.right = "39.5%";

            videoLeftDom.style.width = "9%";
            videoLeftDom.style.top = "45%";
            videoLeftDom.style.left = "39.5%";
            if (homeRef) { homeRef.style.backgroundColor = "#beb5df"; }
            return;

        case ratio >= 0.20:
            if (homeRef) { homeRef.style.backgroundColor = "#cbc4e1"; }
            return;

        case ratio >= 0.18:
            // ratio >= 0.21
            videoRightDom.style.width = "0%";
            videoRightDom.style.top = "52%";
            videoRightDom.style.right = "44%";

            videoLeftDom.style.width = "0%";
            videoLeftDom.style.top = "66%";
            videoLeftDom.style.left = "44%";
            if (homeRef) { homeRef.style.backgroundColor = "#d8d2e2"; }
            return;

        case ratio >= 0.16:
            // ratio >= 0.19
            if (homeRef) { homeRef.style.backgroundColor = "#e4e1e3"; }
            return;
    }
}