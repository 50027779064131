module.exports = {
    public: {
        companyName:"千乘镜像（北京）科技有限公司",
        shareWork:"分享我们的工作成果和科研文章",
        // order: "订单",
        // aboutus:"关于我们",
        // tddata: "数据库",
        // technicalService: "技术服务",
        // product: "产品",
        // article: "热门文章",
        // contact: "联系我们",
    }

}