<template>
  <dialog id="myDialog">
    <div class="header">
      <div class="corp_name" @click="getClientMessage">
        千乘镜像正在为您服务
      </div>
      <div class="opt_handl" @click="closeDialog">关闭</div>
    </div>
    <div class="content">
      <div class="userList" v-if="clientId">
        <ul>
          <div
            v-for="(item, index) in chatList"
            :key="index"
            @click="chatmessgs(item, index)"
          >
            <li
              v-if="item.sender != 'qcjx'"
              :class="{ userItem: true, itemSlected: index == selectedIndex }"
            >
              游客{{ index + 1 }}
            </li>
          </div>
        </ul>
      </div>
      <div class="message_right">
        <!-- 对话框 -->
        <ul class="hismessage">
          <li v-for="(item, index) in chatInfos" :key="index">
            <div class="messageInfoRightBox" v-if="item.opt == 'send'">
              <div v-if="item.sender == 'qcjx'" class="messageInfo_right">
                <div class="clientName">客服</div>
                <div class="clientMsg">
                  <span class="msgSpan">{{ item.message }}</span>
                </div>
              </div>
              <div v-else class="messageInfo_right">
                <div class="clientName">游客</div>
                <div class="clientMsg">
                  <span class="msgSpan">{{ item.message }}</span>
                </div>
              </div>
            </div>
            <div class="messageInfoLeftBox" v-else>
              <div v-if="item.sender == 'qcjx'" class="messageInfo_left">
                <div class="senderName">客服</div>
                <div class="senderMsg">
                  <span class="senderSpan">{{ item.message }}</span>
                </div>
              </div>
              <div v-else class="messageInfo_left">
                <div class="senderName">游客</div>
                <div class="senderMsg">
                  <span class="senderSpan">{{ item.message }}</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div>
          <!-- <div style="height: 2px;width: 100%; background-color:darkgrey;"></div> -->
          <!-- <div class="iconArea" style="background: pink">
            <img src="../assets/image" alt="" />
          </div> -->
          <textarea
            class="messagebox"
            v-model="message"
            @keydown="handleKey"
          ></textarea>
          <div class="footer">
            <button class="sendbox" :disabled="disabled" @click="sendMessage">
              发送
            </button>
          </div>
        </div>
      </div>
    </div>

    <div :class="{ client_block: true, clientShow: isShow }">
      <form class="form">
        <div class="tipTitle">请输入客服账号</div>
        <div class="accountName">
          账号: <input type="text" v-model="clientId" />
        </div>
        <div class="accountPwd">
          密码: <input type="password" v-model="password" />
        </div>
        <div class="btns">
          <input type="submit" value="取消" class="conform" @click="cancel" />
          <input type="submit" value="确认" class="conform" @click="conform" />
        </div>
      </form>
    </div>
  </dialog>
</template>
 
<script>
import { onMounted, ref } from "vue";
import { baseUrl, clientInfo } from "../utils/base.js";
export default {
  setup() {
    const socket = ref(null);
    const userId = ref(null);
    const clientId = ref(null);
    const password = ref(null);
    const isShow = ref(true);
    const message = ref("");
    const connectId = ref(null);
    const disabled = ref(false);
    const chatList = ref([]);
    const chatInfos = ref([]);
    const selectedIndex = ref(null);
    const receiver = ref("");
    onMounted(() => {
      if (!socket.value) {
        userId.value = localStorage.getItem("visitedUserId");
        connectWebSocket(userId.value);
      }
    });
    const getClientMessage = () => {
      isShow.value = false;
    };
    const cancel = () => {
      isShow.value = true;
    };
    const conform = () => {
      // console.log("客服账号连接");
      if (
        clientId.value == clientInfo.id &&
        password.value == clientInfo.pass
      ) {
        if (socket.value) {
          socket.value.onclose();
        }
        localStorage.setItem("clientId", clientId.value);
        connectWebSocket(clientId.value);
        isShow.value = true;
        disabled.value = true;
        chatInfos.value = [];
        password.value = "";
      } else {
        alert("账号密码错误！");
        userId.value = localStorage.getItem("visitedUserId");
        connectWebSocket(userId.value);
        // disabled.value = false;
        // isShow.value = true;
        clientId.value = "";
        password.value = "";
      }
    };
    const closeDialog = () => {
      document.getElementById("myDialog").close();
    };
    const connectWebSocket = (val) => {
      // console.log(val);
      let _this = this;
      if (window.WebSocket) {
        let id = val;
        //生成一个虚拟游客账号
        if (!val) {
          const timestamp = new Date().getTime().toString(36);
          const randomNum = Math.random().toString(36).substring(2, 15);
          id = timestamp + "-" + randomNum;
          localStorage.setItem("visitedUserId", id);
        }

        socket.value = new WebSocket(baseUrl.websocket + id);
        connectId.value = id;
        socket.value.onopen = () => {
          console.log("WebSocket connected");
        };

        socket.value.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        socket.value.onmessage = (message) => {
          if (message.data != "连接成功") {
            let resMess = JSON.parse(message.data);
            resMess.opt = "rec";
            let notExsit = true;
            chatList.value.forEach((element) => {
              if (element.sender == resMess.sender) {
                element.messges.push(resMess);
                notExsit = false;
              }
            });
            if (notExsit) {
              chatList.value.push({
                sender: resMess.sender,
                messges: [resMess],
              });
            }
            chatInfos.value.push(resMess);
            console.log("chatInfos", chatInfos.value);
          }
        };

        socket.value.onclose = () => {
          console.log("WebSocket disconnected");
        };
      }
    };
    const chatmessgs = (val, index) => {
      chatInfos.value = val.messges;
      receiver.value = val.sender;
      disabled.value = false;
      selectedIndex.value = index;
    };
    const sendMessage = () => {
      if (
        message.value &&
        socket.value &&
        socket.value.readyState === WebSocket.OPEN
      ) {
        let sendMs = {
          opt: "send",
          type: 1,
          message: message.value,
          sender: connectId.value,
          receiver: receiver.value,
        };
        socket.value.send(JSON.stringify(sendMs));
        let notExsit = true;
        chatList.value.forEach((element) => {
          if (element.sender == connectId.value) {
            element.messges.push(sendMs);
            notExsit = false;
          }
        });
        if (notExsit) {
          chatList.value.push({ sender: connectId.value, messges: [sendMs] });
        }
        chatInfos.value.push(sendMs);
        message.value = "";
      }
    };

    const handleKey = (event) => {
      if (event.key === "Enter") {
        sendMessage();
      }
    };

    return {
      socket,
      connectWebSocket,
      sendMessage,
      closeDialog,
      userId,
      clientId,
      connectId,
      password,
      conform,
      cancel,
      isShow,
      message,
      chatmessgs,
      chatInfos,
      receiver,
      getClientMessage,
      disabled,
      chatList,
      handleKey,
    };
  },
};
</script>
<style scoped>
dialog {
  /* border: 1px solid #ccc; */
  width: 500px;
  height: 450px;
  margin: auto;
  overflow: hidden;
  border: none;
  border-radius: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 50px;
  background-image: linear-gradient(
    to right,
    rgb(181, 14, 3),
    rgba(181, 14, 3, 0.78)
  );
  background-color: rgb(255, 255, 255);
}
.corp_name {
  line-height: 50px;
  /* color: wheat; */
  color: #fff;
  padding-left: 20px;
  cursor: pointer;
}

.opt_handl {
  line-height: 50px;
  /* color: wheat; */
  color: #fff;
  font-size: 13px;
  padding-right: 20px;
  cursor: pointer;
}
.iconArea {
  /*  */
}

.messagebox {
  width: 100%;
  height: 100px;
  font-size: 14px;
  color: #333333;
  border: none;
  outline: none;
  padding: 10px;
  /* border-top: 2px solid grey; */
}
.messagebox :focus {
  border: none;
  outline: none;
}
.footer {
  position: absolute;
  bottom: 0px;
  right: 10px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
}
.sendbox {
  /* position: absolute;
  right: 20px; */
  font-size: 13px;
  /* color: #9e9e9e !important; */
  color: #fff !important;
  padding: 6px 7px;
  margin-right: 20px;
  border: none;
  /* border: 1px solid #9e9e9e; */
  /* border: 1px solid #b50e03; */
  border-radius: 10px;
  /* background-color: #fff !important; */
  transition: all 0.2s ease;
  background-color: #b50e03 !important;
  cursor: pointer;
}
.sendbox:hover {
  padding: 7px 8px;
}
.client_block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(
    to left,
    rgb(189, 182, 181),
    rgba(189, 182, 181, 0.6)
  );
}
.clientShow {
  visibility: hidden;
}
.form {
  text-align: center;
  padding: 15px;
  /* border: 2px solid grey; */
  background-image: linear-gradient(
    to left,
    rgb(255, 255, 255),
    rgba(255, 255, 255, 1)
  );
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .tipTitle {
    font-size: 14px;
  }
  .accountName {
    font-size: 13px;
    margin: 6px 0px 6px 0px;
    input {
      padding-left: 3px;
    }
  }
  .accountPwd {
    font-size: 13px;
  }
  .btns {
    width: 42%;
    display: flex;
    justify-content: space-between;
    margin: 10px auto 0px auto;
    .conform {
      font-size: 13px;
      padding: 6px 8px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
/* .conform {
  width: 60%;
} */
.content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 400px;
  overflow: hidden;
}
.userList {
  width: 20%;
  height: 100%;
  border-right: 1px solid lightgrey;
}
.message_right {
  width: 100%;
  height: 400px;
  border-right: 1px solid lightgrey;
}
.hismessage {
  width: 100%;
  height: 250px;
  overflow-y: scroll;
  background-color: #e6e6e6;
}
.hismessage::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.itemSlected {
  background-color: lightseagreen;
  color: #ccc;
}
.userItem {
  height: 30px;
  line-height: 30px;
  /* margin-left: 10px; */
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.userItem:hover {
  color: #b50f04;
  font-weight: bold;
}
.messageInfoRightBox {
  text-align: right;
  padding: 5px 15px;
}
.messageInfoLeftBox {
  text-align: left;
  padding: 5px 15px;
}
.messageInfo_right {
  /* display: flex;
  justify-content: flex-end;
  align-items: center; */
  .clientName {
    font-size: 12px;
    color: #9e9e9e;
    margin-top: 3px;
  }
  .clientMsg {
    padding: 8px 0px 8px 8px;
    .msgSpan {
      font-size: 14px;
      color: #fff;
      padding: 8px 10px;
      border-radius: 10px 4px 10px 10px;
      background-color: #b50e03;
    }
  }
}
.messageInfo_left {
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
  .senderName {
    font-size: 12px;
    color: #9e9e9e;
  }
  .senderMsg {
    padding: 8px 8px 8px 0px;
    .senderSpan {
      font-size: 14px;
      color: #1f1f1f;
      padding: 8px 10px;
      border-radius: 4px 10px 10px 10px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      background-color: #feffff;
    }
  }
}
</style>
